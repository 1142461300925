import * as Yup from "yup";
import { signatureValidationSchema } from "../../modules/Signature/createRequirementDefinition/signatureValidationSchema";

const baseValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  type: Yup.string()
    .required("Required")
    .nullable(),
  description: Yup.string().nullable()
});

export const validationSchema = baseValidationSchema.concat(
  signatureValidationSchema
);
