import {
  WSActions,
  WSButton,
  WSCentered,
  WSContainer,
  WSDivider,
  WSForm,
  WSGrid,
  WSInputText,
  WSInputTextarea,
  WSLayout,
  WSList,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import {
  IRequirementDefinitionResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { WS_LINKS } from "../../../../types/wsLinks";
import { CreateRequirementDefinitionFormValues } from "../../types";
import { CreateSignaturePartialForm } from "../../modules/Signature/createRequirementDefinition/CreateSignaturePartialForm";
import { useCreateSignatureRequirementDefinition } from "../../modules/Signature/createRequirementDefinition/useCreateSignatureRequirementDefinition";
import { validationSchema } from "./validationSchema";
import { signatureDefaultValues } from "../../modules/Signature/createRequirementDefinition/signatureDefaultValues";

const REQUIREMENT_TYPE_OPTIONS = [
  {
    value: RequirementType.Signature, // TODO: replace it with new enum if created new one
    label: "e-Signature"
  }
];

type CreateRequirementDefinitionFormProps = {
  engagementId?: string;
  onBack: () => void;
  onSuccess: (requirement: IRequirementDefinitionResponse) => void;
};

const defaultValues: Partial<CreateRequirementDefinitionFormValues> = {
  type: REQUIREMENT_TYPE_OPTIONS[0].value,
  ...signatureDefaultValues
};

export const CreateRequirementDefinitionForm: React.FC<CreateRequirementDefinitionFormProps> = ({
  engagementId,
  onBack,
  onSuccess
}) => {
  const signatureRequirementCreation = useCreateSignatureRequirementDefinition({
    engagementId,
    onSuccess
  });

  return (
    <WSLayout
      headerCenter={
        <WSText.ParagraphSm weight="medium">
          Create requirement
        </WSText.ParagraphSm>
      }
      onClose={onBack}
    >
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "10", m: "8", l: "6" }}>
          <WSText.Paragraph weight="medium" mb="S">
            Requirement information
          </WSText.Paragraph>

          <WSForm<CreateRequirementDefinitionFormValues>
            defaultValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={async formValues => {
              if (formValues.type === RequirementType.Signature) {
                await signatureRequirementCreation.onSubmit(formValues);
              }
            }}
          >
            <WSList gap="XL">
              <WSGrid gutter={{ m: "2XL" }}>
                <WSGrid.Item span={{ m: "6", s: "12", xs: "12" }}>
                  <WSForm.Field
                    name="name"
                    label="Name"
                    placeholder="Requirement name"
                    component={WSInputText}
                    componentProps={{
                      required: true
                    }}
                  />
                </WSGrid.Item>

                <WSGrid.Item span={{ m: "6", s: "12", xs: "12" }}>
                  <WSForm.Field
                    name="type"
                    label="Type"
                    placeholder="Requirement type"
                    component={WSSelect}
                    componentProps={{
                      required: true,
                      options: REQUIREMENT_TYPE_OPTIONS
                    }}
                  />
                </WSGrid.Item>
              </WSGrid>

              <WSForm.Field
                name="description"
                label="Description"
                placeholder="Requirement description"
                component={WSInputTextarea}
              />
            </WSList>

            <WSDivider my="XL" />
            <CreateSignaturePartialForm />

            <WSDivider my="XL" />

            <WSErrorMessage
              mb="M"
              contextKey={ErrorContextKey.Other}
              error={signatureRequirementCreation.errors}
            />

            <WSForm.Data<CreateRequirementDefinitionFormValues>>
              {formData => (
                <WSActions
                  alignment="fill"
                  orientation="horizontal"
                  buttons={[
                    {
                      kind: "Tertiary",
                      label: "Cancel",
                      onClick: onBack
                    },
                    {
                      kind: "Primary",
                      label: "Continue",
                      type: "submit",
                      loading: signatureRequirementCreation.isLoading,
                      disabled: !validationSchema.isValidSync(formData)
                    }
                  ]}
                />
              )}
            </WSForm.Data>
          </WSForm>
          <WSText.ParagraphXs weight="book" color="gray400" my="XL">
            Wingspan can auto-fill known info like names, emails, custom fields
            which you can configure in the next step.
          </WSText.ParagraphXs>
          <WSButton.Link
            rightIcon="chevron-right"
            type="button"
            size="S"
            onClick={() => openInNewTab(WS_LINKS.eligibilityRequirements)}
          >
            Learn more about requirements
          </WSButton.Link>
        </WSCentered>
      </WSContainer>
    </WSLayout>
  );
};
