import {
  InfiniteQueryConfig,
  queryCache,
  QueryConfig,
  QueryStatus
} from "react-query";

import { ICardResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  BulkStatus,
  FrequencyAndScheduleStatus,
  IBankStatement,
  IBulkCollaboratorBatch,
  IBulkPayableBatch,
  IClientInvoice,
  ICollaboratorEvents,
  ICollaboratorGroupResponse,
  ICollaboratorSchema,
  ICollaboratorsReportRequest,
  ICollaboratorV2,
  IInvoice,
  IInvoiceFeeCalculation,
  IInvoiceTemplate,
  IMemberClient,
  InvoiceStatus,
  IPayableSchema,
  IPayrollSettings,
  IServiceStatusResponse,
  MemberClientStatus,
  MemberClientTaxStatus
} from "@wingspanhq/payments/dist/interfaces";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import {
  ICollaboratorsPayoutsSummaryReportRequest,
  ICollaboratorsPayoutsSummaryReportResponse,
  ICollaboratorsReportResponse,
  ILineItemsAgingReportRequest,
  ILineItemsAgingReportResponse,
  IPayableAgingReportRequest,
  IPayableAgingReportResponse,
  IPayrollReportResponse
} from "@wingspanhq/payments/dist/interfaces/api/reports";
import { IBulkCalculation1099Batch } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import {
  DeductionStatus,
  DeductionType
} from "@wingspanhq/payments/dist/interfaces/deductions";
import { IEligibilityRequirement } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import flatten from "lodash/flatten";
import times from "lodash/times";
import { TRACK_FE_PERFORMANCE_DURATION } from "../../constants/analytics";
import {
  CollaboratorFilter,
  ICardDetailsResponse,
  InvoiceListFilter,
  InvoiceListQuery,
  InvoiceListStats,
  InvoiceTemplateListQuery,
  MemberClientListQuery,
  PayablesFilter,
  PayablesWithSummary,
  paymentsService
} from "../../services/payments";
import { useAuthorizedScopeGroups } from "../../shared/utils/teamUtils";
import { Await } from "../../utils";
import { track } from "../../utils/analytics";
import {
  concurrentActions,
  getAllEntries,
  ListRequestQuery,
  WSServiceError
} from "../../utils/serviceHelper";
import { useWSInfiniteQuery, useWSQuery } from "../helpers";
import {
  QUERY_ALL_COLLABORATOR_GROUPS,
  QUERY_BANK_INSTITUTION,
  QUERY_BANK_STATEMENT,
  QUERY_BANK_STATEMENTS_LIST,
  QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST,
  QUERY_BULK_COLLABORATOR_BATCH_ITEM_NOT_STARTED_LIST,
  QUERY_BULK_PAYABLE_BATCH,
  QUERY_BULK_PAYABLE_BATCH_ITEM_LIST,
  QUERY_BULK_PAYABLE_BATCH_ITEM_NOT_STARTED_LIST,
  QUERY_BULK_PAYABLE_BATCH_ITEM_PROCESSED_LIST,
  QUERY_CARD,
  QUERY_CARD_LIST,
  QUERY_CLIENT_INVOICE,
  QUERY_CLIENT_INVOICE_FEES,
  QUERY_CLIENT_V2,
  QUERY_COLLABORATOR,
  QUERY_COLLABORATOR_DEDUCTION,
  QUERY_COLLABORATOR_DEDUCTIONS,
  QUERY_COLLABORATOR_EVENTS,
  QUERY_COLLABORATOR_GROUP,
  QUERY_COLLABORATOR_GROUPS,
  QUERY_COLLABORATOR_OPEN_PAYABLES,
  QUERY_COLLABORATOR_V2,
  QUERY_COLLABORATORS,
  QUERY_COLLABORATORS_V2,
  QUERY_ELIGIBILITY_REQUIREMENT,
  QUERY_ELIGIBILITY_REQUIREMENTS,
  QUERY_INVOICE,
  QUERY_INVOICE_TEMPLATE,
  QUERY_INVOICE_TEMPLATES,
  QUERY_INVOICES,
  QUERY_MEMBER_CLIENT,
  QUERY_MEMBER_CLIENTS,
  QUERY_MISSING_INFO_COLLABORATORS,
  QUERY_OUTSTANDING_INVOICES,
  QUERY_OUTSTANDING_INVOICES_STATS,
  QUERY_PAID_INVOICES,
  QUERY_PAID_INVOICES_STATS,
  QUERY_PAYABLES,
  QUERY_PAYMENTS_STATUS,
  QUERY_PAYOUT_SETTINGS,
  QUERY_PAYOUT_SETTINGS_DEBIT_CARDS,
  QUERY_PAYROLL_IMMEDIATE_PAYABLES,
  QUERY_PAYROLL_SETTINGS,
  QUERY_RECENT_MEMBER_CLIENTS,
  QUERY_REPORTS_COLLABORATOR_PAYABLES_SUMMARY,
  QUERY_REPORTS_COLLABORATORS,
  QUERY_REPORTS_OPEN_LINE_ITEM_AGING,
  QUERY_REPORTS_OPEN_PAYABLES_AGING,
  QUERY_REPORTS_OPEN_RECEIVABLE_AGING,
  QUERY_REPORTS_PAYROLL,
  QUERY_UNSENT_INVOICES
} from "./keys";
import { getPayeeEngagements } from "../../services/payeeEngagement";
import {
  IReceivableAgingReportResponse,
  IReceivableAgingReportRequest
} from "../../modules/Reports/routes/RouteOpenReceivableAging/mocksTypes";

export const usePaymentsStatusQuery = (
  queryConfig?: QueryConfig<IServiceStatusResponse, WSServiceError>
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();

  return useWSQuery(QUERY_PAYMENTS_STATUS, paymentsService.service.get, {
    ...queryConfig,
    enabled: hasPaymentsScope
  });
};

export enum InvoicesDateRangeFilter {
  All = "All",
  PastWeek = "PastWeek",
  PastMonth = "PastMonth",
  PastYear = "PastYear",
  Custom = "Custom"
}

export const useInvoicesQuery = (
  config?: QueryConfig<IInvoice[], WSServiceError>
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();
  const startTime = Date.now();
  return useWSQuery<IInvoice[], WSServiceError>(
    QUERY_INVOICES,
    () =>
      getAllByParts<InvoiceListQuery>(
        "invoice",
        QUERY_INVOICES,
        {
          filter: {
            status: {
              in: [
                InvoiceStatus.Draft,
                InvoiceStatus.Open,
                InvoiceStatus.Overdue,
                InvoiceStatus.Paid,
                InvoiceStatus.PaymentInTransit,
                InvoiceStatus.Pending
              ]
            },
            "labels.invoiceType": {
              "!=": "approvedInvoicesPayment"
            }
          },
          sort: {
            createdAt: "desc"
          }
        },
        undefined,
        () => {
          track(TRACK_FE_PERFORMANCE_DURATION, {
            duration: Date.now() - startTime,
            name: "QueryInvoices",
            is_ended_because_of_error: false
          });
        }
      ),
    {
      onError: () => {
        track(TRACK_FE_PERFORMANCE_DURATION, {
          duration: Date.now() - startTime,
          name: "QueryInvoices",
          is_ended_because_of_error: true
        });
      },
      refetchOnMount: false,
      retry: false,
      enabled: hasPaymentsScope,
      ...config
    }
  );
};

export const useInvoicesFilteredQuery = (
  filters?: InvoiceListFilter,
  config?: QueryConfig<IInvoice[], WSServiceError>
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();
  const startTime = Date.now();
  return useWSQuery<IInvoice[], WSServiceError>(
    [QUERY_INVOICES, filters],
    async () => {
      const results = await getAllEntries(
        paymentsService.invoice.list,
        filters,
        {
          createdAt: "desc"
        }
      );
      track(TRACK_FE_PERFORMANCE_DURATION, {
        duration: Date.now() - startTime,
        name: "QueryInvoicesFiltered",
        is_ended_because_of_error: false
      });
      return results;
    },
    {
      onError: () => {
        track(TRACK_FE_PERFORMANCE_DURATION, {
          duration: Date.now() - startTime,
          name: "QueryInvoicesFiltered",
          is_ended_because_of_error: true
        });
      },
      refetchOnMount: false,
      retry: false,
      enabled: hasPaymentsScope,
      ...config
    }
  );
};

export const useQueryUnsentInvoices = (
  config?: QueryConfig<IInvoice[], WSServiceError>
) => {
  const query = useWSQuery<IInvoice[], WSServiceError>(
    QUERY_UNSENT_INVOICES,
    () =>
      getAllByParts<InvoiceListQuery>("invoice", QUERY_UNSENT_INVOICES, {
        filter: {
          status: { in: [InvoiceStatus.Draft, InvoiceStatus.Pending] },
          "labels.invoiceType": {
            "!=": "approvedInvoicesPayment"
          }
        },
        sort: {
          createdAt: "desc"
        }
      }),
    config
  );

  if (queryCache.getQueryData(getPartialQueryKey(QUERY_UNSENT_INVOICES))) {
    query.status = QueryStatus.Loading;
    query.isLoading = true;
  }

  return query;
};

export const useQueryOutstandingInvoices = (
  config?: QueryConfig<IInvoice[], WSServiceError>
) => {
  const query = useWSQuery<IInvoice[], WSServiceError>(
    QUERY_OUTSTANDING_INVOICES,
    () => {
      return getAllByParts<InvoiceListQuery>(
        "invoice",
        QUERY_OUTSTANDING_INVOICES,
        {
          filter: {
            status: { in: [InvoiceStatus.Open, InvoiceStatus.Overdue] },
            "labels.invoiceType": {
              "!=": "approvedInvoicesPayment"
            }
          },
          sort: {
            createdAt: "desc"
          }
        }
      );
    },
    config
  );

  if (queryCache.getQueryData(getPartialQueryKey(QUERY_OUTSTANDING_INVOICES))) {
    query.status = QueryStatus.Loading;
    query.isLoading = true;
  }

  return query;
};

export const useQueryPaidInvoices = (
  config?: QueryConfig<IInvoice[], WSServiceError>
) => {
  const query = useWSQuery<IInvoice[], WSServiceError>(
    QUERY_PAID_INVOICES,
    () =>
      getAllByParts<InvoiceListQuery>("invoice", QUERY_PAID_INVOICES, {
        filter: {
          status: { in: [InvoiceStatus.Paid, InvoiceStatus.PaymentInTransit] },
          "labels.invoiceType": {
            "!=": "approvedInvoicesPayment"
          }
        },
        sort: {
          "events.paidAt": "desc"
        }
      }),
    config
  );

  if (queryCache.getQueryData(getPartialQueryKey(QUERY_PAID_INVOICES))) {
    query.status = QueryStatus.Loading;
    query.isLoading = true;
  }

  return query;
};

export const useQueryOutstandingInvoicesStats = (
  config?: QueryConfig<InvoiceListStats, WSServiceError>
) => {
  return useWSQuery<InvoiceListStats, WSServiceError>(
    QUERY_OUTSTANDING_INVOICES_STATS,
    () =>
      paymentsService.invoice.listStats({
        filter: {
          status: { in: [InvoiceStatus.Open, InvoiceStatus.Overdue] },
          "labels.invoiceType": {
            "!=": "approvedInvoicesPayment"
          }
        }
      }),
    config
  );
};

export const useQueryPaidInvoicesStats = (
  config?: QueryConfig<InvoiceListStats, WSServiceError>
) => {
  return useWSQuery<InvoiceListStats, WSServiceError>(
    QUERY_PAID_INVOICES_STATS,
    () =>
      paymentsService.invoice.listStats({
        filter: {
          status: { in: [InvoiceStatus.Paid, InvoiceStatus.PaymentInTransit] },
          "labels.invoiceType": {
            "!=": "approvedInvoicesPayment"
          }
        }
      }),
    config
  );
};

export const useInvoiceQuery = (
  invoiceId: string,
  config?: QueryConfig<IInvoice, WSServiceError>
) => {
  return useWSQuery<IInvoice, WSServiceError>(
    [QUERY_INVOICE, invoiceId],
    () => paymentsService.invoice.get(invoiceId),
    {
      ...config
    }
  );
};

export const useInvoiceTemplatesQuery = (
  config?: QueryConfig<IInvoiceTemplate[], WSServiceError>
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();

  const query = useWSQuery<IInvoiceTemplate[], WSServiceError>(
    QUERY_INVOICE_TEMPLATES,
    () =>
      getAllByParts<InvoiceTemplateListQuery>(
        "invoiceTemplate",
        QUERY_INVOICE_TEMPLATES,
        {
          filter: {
            status: {
              in: [
                FrequencyAndScheduleStatus.Active,
                FrequencyAndScheduleStatus.Draft
              ]
            }
          },
          sort: {
            createdAt: "desc"
          }
        }
      ),
    {
      refetchOnMount: false,
      retry: false,
      enabled: hasPaymentsScope,
      ...config
    }
  );

  if (queryCache.getQueryData(getPartialQueryKey(QUERY_INVOICE_TEMPLATES))) {
    query.status = QueryStatus.Loading;
    query.isLoading = true;
  }

  return query;
};

export const useInvoiceTemplateQuery = (
  invoiceTemplateId: string,
  config?: QueryConfig<IInvoiceTemplate, WSServiceError>
) => {
  return useWSQuery<IInvoiceTemplate, WSServiceError>(
    [QUERY_INVOICE_TEMPLATE, invoiceTemplateId],
    () => paymentsService.invoiceTemplate.get(invoiceTemplateId),
    {
      ...config
    }
  );
};

export const useMemberClientsQuery = (
  config?: QueryConfig<IMemberClient[], WSServiceError>
) => {
  const startTime = Date.now();
  const query = useWSQuery<IMemberClient[], WSServiceError>(
    QUERY_MEMBER_CLIENTS,
    () =>
      getAllByParts<MemberClientListQuery>(
        "memberClient",
        QUERY_MEMBER_CLIENTS,
        {
          sort: {
            updatedAt: "asc"
          }
        },
        5,
        () => {
          track(TRACK_FE_PERFORMANCE_DURATION, {
            duration: Date.now() - startTime,
            name: "QueryMemberClients",
            is_ended_because_of_error: false
          });
        }
      ),
    {
      onError: () => {
        track(TRACK_FE_PERFORMANCE_DURATION, {
          duration: Date.now() - startTime,
          name: "QueryMemberClients",
          is_ended_because_of_error: true
        });
      },
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );

  if (queryCache.getQueryData(getPartialQueryKey(QUERY_MEMBER_CLIENTS))) {
    query.status = QueryStatus.Loading;
    query.isLoading = true;
  }

  return query;
};

export const useRecentMemberClientsQuery = (
  config?: QueryConfig<IMemberClient[], WSServiceError>
) => {
  return useWSQuery<IMemberClient[], WSServiceError>(
    QUERY_RECENT_MEMBER_CLIENTS,
    () =>
      paymentsService.memberClient.list({
        filter: {
          status: {
            in: [MemberClientStatus.Active, MemberClientStatus.Pending]
          }
        },
        sort: {
          updatedAt: "desc"
        },
        page: {
          number: 1,
          size: 4
        }
      }),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useMemberClientQuery = (
  memberClientId: string,
  config?: QueryConfig<IMemberClient, WSServiceError>
) => {
  return useWSQuery<IMemberClient, WSServiceError>(
    [QUERY_MEMBER_CLIENT, memberClientId],
    () => paymentsService.memberClient.get(memberClientId),
    config
  );
};

export const useMemberClientV2Query = (
  memberClientId: string,
  config?: QueryConfig<ICollaboratorV2, WSServiceError>
) => {
  return useWSQuery<ICollaboratorV2, WSServiceError>(
    [QUERY_CLIENT_V2, memberClientId],
    () => paymentsService.memberClient.getV2(memberClientId),
    config
  );
};

export interface ICollaboratorsFilters {
  taxStatus?: MemberClientTaxStatus;
  bulkBatchId?: string;
}

const mapCollaboratorsFilters = (
  filters?: ICollaboratorsFilters
): CollaboratorFilter => {
  const filter: CollaboratorFilter = {};

  if (filters?.bulkBatchId) {
    filter["labels.bulkBatchId"] = filters.bulkBatchId;
  }

  if (filters?.taxStatus) {
    filter["taxStatus"] = {
      "=": MemberClientTaxStatus.Incomplete
    };
  }

  return filter;
};

export const useMissingInfoCollaboratorsQuery = (
  config?: QueryConfig<ICollaboratorSchema[], WSServiceError>
) => {
  const _filters: CollaboratorFilter = {
    taxStatus: {
      "=": MemberClientTaxStatus.Incomplete
    }
  };
  return useWSQuery<ICollaboratorSchema[], WSServiceError>(
    [QUERY_MISSING_INFO_COLLABORATORS, { _filters }],
    async () => {
      const listSize = await paymentsService.collaborator.listSize();
      const pages = Math.ceil(listSize / 100);

      const actions = times(pages).map((_, i) => () =>
        paymentsService.collaborator.list({
          filter: _filters,
          page: {
            size: 100,
            number: i + 1
          }
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten(allPages);
    },
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useCollaboratorsQuery = (
  filters?: ICollaboratorsFilters,
  config?: QueryConfig<ICollaboratorSchema[], WSServiceError>
) => {
  const startTime = Date.now();
  return useWSQuery<ICollaboratorSchema[], WSServiceError>(
    [QUERY_COLLABORATORS, { filters }],
    async () => {
      const listSize = await paymentsService.collaborator.listSize();
      const pages = Math.ceil(listSize / 100);

      const actions = times(pages).map((_, i) => () =>
        paymentsService.collaborator.list({
          filter: mapCollaboratorsFilters(filters),
          page: { size: 100, number: i + 1 }
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      track(TRACK_FE_PERFORMANCE_DURATION, {
        duration: Date.now() - startTime,
        name: "QueryCollaborators",
        is_ended_because_of_error: false
      });

      const payload = flatten(allPages);

      return payload;
    },
    {
      refetchOnMount: false,
      retry: false,
      onError: (error: WSServiceError) => {
        track(TRACK_FE_PERFORMANCE_DURATION, {
          duration: Date.now() - startTime,
          name: "QueryCollaborators",
          is_ended_because_of_error: true
        });
        throw error;
      },
      ...config
    }
  );
};

const loadCollaboratorsV2FromList = async (
  collaborators: ICollaboratorV2[]
) => {
  console.log("loading collaborators", collaborators.length);
  const loadPromises = collaborators.map(collaborator => {
    const { memberId } = collaborator;
    queryCache.setQueryData([QUERY_COLLABORATOR_V2, memberId], collaborator);
  });

  Promise.all(loadPromises).then(() => {
    console.log("done");
  });
};

export const useCollaboratorsV2Query = (
  filters?: ICollaboratorsFilters,
  config?: QueryConfig<ICollaboratorV2[], WSServiceError>
) => {
  return useWSQuery<ICollaboratorV2[], WSServiceError>(
    [QUERY_COLLABORATORS_V2],
    async () => {
      const listSize = await paymentsService.collaborator.listV2Size();
      const pages = Math.ceil(listSize / 1000);

      const actions = times(pages).map((_, i) => () =>
        paymentsService.collaborator.listV2({
          page: { size: 1000, number: i + 1 }
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      const allCollaborators = flatten(allPages);

      // don't need this anymore but an interesting idea
      // loadCollaboratorsV2FromList(allCollaborators);

      return allCollaborators;
    },
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useCollaboratorQuery = (
  collaboratorId: string,
  config?: QueryConfig<ICollaboratorSchema, WSServiceError>
) => {
  return useWSQuery<ICollaboratorSchema, WSServiceError>(
    [QUERY_COLLABORATOR, collaboratorId],
    () => paymentsService.collaborator.get(collaboratorId),
    config
  );
};

export const useCollaboratorV2Query = (
  memberId: string,
  config?: QueryConfig<ICollaboratorV2, WSServiceError>
) => {
  return useWSQuery<ICollaboratorV2, WSServiceError>(
    [QUERY_COLLABORATOR_V2, memberId],
    () => paymentsService.collaborator.getV2(memberId),
    config
  );
};

export const useCollaboratorEventsQuery = (
  collaboratorId: string,
  config?: QueryConfig<ICollaboratorEvents, WSServiceError>
) => {
  return useWSQuery<ICollaboratorEvents, WSServiceError>(
    [QUERY_COLLABORATOR_EVENTS, collaboratorId],
    () => paymentsService.collaborator.getEvents(collaboratorId),
    config
  );
};

export const useClientInvoiceQuery = (
  id: string,
  config?: QueryConfig<IClientInvoice, WSServiceError>
) => {
  return useWSQuery<IClientInvoice, WSServiceError>(
    [QUERY_CLIENT_INVOICE, id],
    () => paymentsService.client.invoice.get(id),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useClientInvoiceFeesQuery = (
  id: string,
  config?: QueryConfig<IInvoiceFeeCalculation, WSServiceError>
) => {
  return useWSQuery<IInvoiceFeeCalculation, WSServiceError>(
    [QUERY_CLIENT_INVOICE_FEES, id],
    () => paymentsService.client.invoice.getFees(id),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useBankStatementsList = (
  config?: QueryConfig<IBankStatement[], WSServiceError>
) => {
  return useWSQuery<IBankStatement[], WSServiceError>(
    QUERY_BANK_STATEMENTS_LIST,
    paymentsService.banking.statement.list,
    {
      refetchOnMount: false,
      ...config
    }
  );
};

export const useGetBankStatement = (
  statementId: string,
  config?: QueryConfig<any, WSServiceError>
) => {
  return useWSQuery<any, WSServiceError>(
    QUERY_BANK_STATEMENT,
    () => paymentsService.banking.statement.get(statementId),
    {
      refetchOnMount: false,
      ...config
    }
  );
};

export const usePayrollSettings = (
  memberId: string,
  config?: QueryConfig<IPayrollSettings, WSServiceError>
) => {
  return useWSQuery<IPayrollSettings, WSServiceError>(
    QUERY_PAYROLL_SETTINGS,
    () => paymentsService.payrollSettings.get(memberId),
    config
  );
};

export const useCardsQuery = (
  config?: QueryConfig<ICardResponse[], WSServiceError>
) => {
  return useWSQuery<ICardResponse[], WSServiceError>(
    QUERY_CARD_LIST,
    paymentsService.banking.card.list,
    {
      retry: false,
      refetchOnMount: false,
      ...config
    }
  );
};

export const useCardQuery = (
  id: string,
  config?: QueryConfig<ICardDetailsResponse, WSServiceError>
) => {
  return useWSQuery<ICardDetailsResponse, WSServiceError>(
    [QUERY_CARD, id],
    () => paymentsService.banking.card.get(id),
    {
      refetchOnMount: false,
      ...config
    }
  );
};

export const useCollaboratorGroupsQuery = (
  config?: QueryConfig<ICollaboratorGroupResponse[], WSServiceError>
) => {
  return useWSQuery<ICollaboratorGroupResponse[], WSServiceError>(
    QUERY_COLLABORATOR_GROUPS,
    () => getAllEntries(paymentsService.collaboratorGroup.list),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useCollaboratorGroupQuery = (
  collaboratorGroupId: string,
  config?: QueryConfig<ICollaboratorGroupResponse, WSServiceError>
) => {
  return useWSQuery<ICollaboratorGroupResponse, WSServiceError>(
    [QUERY_COLLABORATOR_GROUP, collaboratorGroupId],
    () => paymentsService.collaboratorGroup.get(collaboratorGroupId),
    config
  );
};

export const useAllCollaboratorGroupsQuery = (
  sort: { [key: string]: "asc" | "desc" | undefined },
  queryConfig?: InfiniteQueryConfig<
    ICollaboratorGroupResponse[],
    WSServiceError
  >
) =>
  useWSInfiniteQuery(
    [QUERY_ALL_COLLABORATOR_GROUPS, sort],
    async (query, queryParams, pageNumber = 1) => {
      return await paymentsService.collaboratorGroup.list({
        page: {
          size: 10,
          number: pageNumber
        },
        sort
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...queryConfig
    }
  );

export const useEligibilityRequirementQuery = (
  id: string,
  config?: QueryConfig<IEligibilityRequirement, WSServiceError>
) => {
  return useWSQuery<IEligibilityRequirement, WSServiceError>(
    [QUERY_ELIGIBILITY_REQUIREMENT, id],
    () => paymentsService.collaboratorSettings.eligibilityRequirements.get(id),
    config
  );
};

export const useEligibilityRequirementsQuery = (
  config?: QueryConfig<IEligibilityRequirement[], WSServiceError>
) => {
  return useWSQuery<IEligibilityRequirement[], WSServiceError>(
    QUERY_ELIGIBILITY_REQUIREMENTS,
    () =>
      getAllEntries(
        paymentsService.collaboratorSettings.eligibilityRequirements.list
      ),
    config
  );
};

export const usePayoutSettings = (
  memberId: string,
  config?: QueryConfig<
    Await<ReturnType<typeof paymentsService.payoutSettings.get>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.payoutSettings.get>>,
    WSServiceError
  >(QUERY_PAYOUT_SETTINGS, () => paymentsService.payoutSettings.get(memberId), {
    refetchOnMount: false,
    ...config
  });
};

export const usePayoutSettingsDebitCards = (
  memberId: string,
  config?: QueryConfig<
    Await<ReturnType<typeof paymentsService.payoutSettings.debitCard.list>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.payoutSettings.debitCard.list>>,
    WSServiceError
  >(
    [QUERY_PAYOUT_SETTINGS_DEBIT_CARDS, memberId],
    () => paymentsService.payoutSettings.debitCard.list(memberId),
    {
      refetchOnMount: false,
      ...config
    }
  );
};

export const usePayoutSettingsDebitCard = (
  memberId: string,
  debitCardId: string,
  config?: QueryConfig<
    Await<ReturnType<typeof paymentsService.payoutSettings.debitCard.get>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.payoutSettings.debitCard.get>>,
    WSServiceError
  >(
    [`QUERY_PAYOUT_SETTINGS_DEBIT_CARD-${debitCardId}`, memberId],
    () => paymentsService.payoutSettings.debitCard.get(memberId, debitCardId),
    {
      refetchOnMount: false,
      ...config
    }
  );
};

export const useBankInstitution = (
  institutionId: string,
  config?: QueryConfig<
    Await<ReturnType<typeof paymentsService.banking.institution.get>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.banking.institution.get>>,
    WSServiceError
  >(
    [QUERY_BANK_INSTITUTION, institutionId],
    () => paymentsService.banking.institution.get(institutionId),
    config
  );
};

export const useCollaboratorDeduction = (
  id: string,
  config?: QueryConfig<IDeductionResponse, WSServiceError>
) => {
  return useWSQuery<IDeductionResponse, WSServiceError>(
    [QUERY_COLLABORATOR_DEDUCTION, id],
    () => paymentsService.collaboratorDeductions.get(id),
    config
  );
};

export const useCollaboratorDeductions = (
  params: {
    memberId?: string;
    clientId?: string;
    status?: DeductionStatus | { in: DeductionStatus[] };
    type?: DeductionType | { in: DeductionType[] };
  },
  config?: QueryConfig<IDeductionResponse[], WSServiceError>
) => {
  return useWSQuery<IDeductionResponse[], WSServiceError>(
    [QUERY_COLLABORATOR_DEDUCTIONS, params],
    () =>
      paymentsService.collaboratorDeductions.list({
        filter: params,
        page: { size: 100 }
      }),
    config
  );
};

export const useAllCollaboratorDeductions = (
  params: {
    memberId?: string;
    clientId?: string;
    status?: DeductionStatus | { in: DeductionStatus[] };
    "labels.bulkBatchId"?: string;
    type?: DeductionType | { in: DeductionType[] };
    sort?: { name?: "desc" | "asc"; "events.createdAt"?: "desc" | "asc" };
  },
  config?: InfiniteQueryConfig<IDeductionResponse[], WSServiceError>
) => {
  const { sort, ...filter } = params;

  return useWSInfiniteQuery<IDeductionResponse[], WSServiceError>(
    [QUERY_COLLABORATOR_DEDUCTIONS, params],
    async (query, queryParams, pageNumber = 1) => {
      return paymentsService.collaboratorDeductions.list({
        filter,
        page: { size: 10, number: pageNumber },
        sort
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...config
    }
  );
};

export const useBulkPayableBatch = (
  batchId: string,
  config?: QueryConfig<IBulkPayableBatch, WSServiceError>
) => {
  return useWSQuery<IBulkPayableBatch, WSServiceError>(
    [QUERY_BULK_PAYABLE_BATCH, batchId],
    () => paymentsService.bulkPayable.batch.get(batchId),
    config
  );
};

export const useBulkPayableBatchItems = (
  batchId: string,
  status?: BulkStatus[],
  config?: QueryConfig<
    Await<ReturnType<typeof paymentsService.bulkPayable.batchItem.list>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.bulkPayable.batchItem.list>>,
    WSServiceError
  >(
    QUERY_BULK_PAYABLE_BATCH_ITEM_LIST,
    () =>
      paymentsService.bulkPayable.batchItem.list(
        batchId,
        status?.length
          ? status
          : [
              BulkStatus.Open,
              BulkStatus.Pending,
              BulkStatus.Processing,
              BulkStatus.Complete,
              BulkStatus.Failed
            ]
      ),
    config
  );
};

export const useBulkPayableBatchItemsProcessed = (
  batchId: string,
  status: BulkStatus[],
  config?: QueryConfig<
    Await<ReturnType<typeof paymentsService.bulkPayable.batchItem.list>>,
    WSServiceError
  >
) => {
  const _status = status.length
    ? status
    : [BulkStatus.Complete, BulkStatus.Failed];
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.bulkPayable.batchItem.list>>,
    WSServiceError
  >(
    [QUERY_BULK_PAYABLE_BATCH_ITEM_PROCESSED_LIST, _status],
    () => paymentsService.bulkPayable.batchItem.list(batchId, _status),
    config
  );
};

export const useBulkPayableBatchItemsNotYetProcessed = (
  batchId: string,
  config?: QueryConfig<
    Await<ReturnType<typeof paymentsService.bulkPayable.batchItem.list>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.bulkPayable.batchItem.list>>,
    WSServiceError
  >(
    QUERY_BULK_PAYABLE_BATCH_ITEM_NOT_STARTED_LIST,
    () =>
      paymentsService.bulkPayable.batchItem.list(batchId, [
        BulkStatus.Open,
        BulkStatus.Pending,
        BulkStatus.Processing
      ]),
    config
  );
};

export const useBulkCollaboratorBatch = (
  batchId: string,
  config?: QueryConfig<IBulkCollaboratorBatch, WSServiceError>
) => {
  return useWSQuery<IBulkCollaboratorBatch, WSServiceError>(
    [QUERY_BULK_PAYABLE_BATCH, batchId],
    () => paymentsService.bulkCollaborator.batch.get(batchId),
    config
  );
};

export const useBulkCollaboratorBatchItems = (
  batchId: string,
  status?: BulkStatus[],
  config?: QueryConfig<
    Await<ReturnType<typeof paymentsService.bulkCollaborator.batchItem.list>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.bulkCollaborator.batchItem.list>>,
    WSServiceError
  >(
    QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST,
    () =>
      paymentsService.bulkCollaborator.batchItem.list(
        batchId,
        status?.length
          ? status
          : [
              BulkStatus.Open,
              BulkStatus.Pending,
              BulkStatus.Processing,
              BulkStatus.Complete,
              BulkStatus.Failed
            ]
      ),
    config
  );
};

export const useBulkCollaboratorBatchItemsNotYetProcessed = (
  batchId: string,
  config?: QueryConfig<
    Await<ReturnType<typeof paymentsService.bulkCollaborator.batchItem.list>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.bulkCollaborator.batchItem.list>>,
    WSServiceError
  >(
    QUERY_BULK_COLLABORATOR_BATCH_ITEM_NOT_STARTED_LIST,
    () =>
      paymentsService.bulkCollaborator.batchItem.list(batchId, [
        BulkStatus.Open,
        BulkStatus.Pending,
        BulkStatus.Processing
      ]),
    config
  );
};

export const useBulkCalculate1099Batch = (
  batchId: string,
  config?: QueryConfig<IBulkCalculation1099Batch, WSServiceError>
) => {
  return useWSQuery<IBulkCalculation1099Batch, WSServiceError>(
    [QUERY_BULK_PAYABLE_BATCH, batchId],
    () =>
      paymentsService.collaborator.nec1099.bulkCalculation1099Batch.get(
        batchId
      ),
    config
  );
};

export const useCollaboratorOpenPayblesQuery = (
  collaboratorId: string,
  config?: QueryConfig<IPayableSchema[], WSServiceError>
) => {
  return useWSQuery<IPayableSchema[], WSServiceError>(
    [QUERY_COLLABORATOR_OPEN_PAYABLES, collaboratorId],
    async () => {
      const { data: payables } = await paymentsService.payable.list({
        filter: {
          memberClientId: collaboratorId,
          status: {
            in: [InvoiceStatus.Open, InvoiceStatus.Overdue]
          }
        }
      });
      return payables;
    },

    config
  );
};

export const usePayeeOpenPayblesQuery = (
  payeeId: string,
  config?: QueryConfig<IPayableSchema[], WSServiceError>
) => {
  return useWSQuery<IPayableSchema[], WSServiceError>(
    [QUERY_COLLABORATOR_OPEN_PAYABLES, payeeId],
    async () => {
      const { data: engagements } = await getPayeeEngagements(payeeId);
      const engagementIds = engagements.map(e => e.payerPayeeEngagementId);
      const { data: payables } = await paymentsService.payable.list({
        filter: {
          memberClientId: {
            in: engagementIds
          },
          status: {
            in: [InvoiceStatus.Open, InvoiceStatus.Overdue]
          }
        }
      });
      return payables;
    },

    config
  );
};

export const useCollaboratorsReportsQuery = (
  params: ICollaboratorsReportRequest,
  config?: QueryConfig<ICollaboratorsReportResponse[], WSServiceError>
) => {
  return useWSQuery<ICollaboratorsReportResponse[], WSServiceError>(
    [QUERY_REPORTS_COLLABORATORS, params],
    async () => {
      const pageSize = 500;
      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.collaborators(params, {
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map((_, i) => () =>
        paymentsService.reports.collaborators(params, {
          page: { size: pageSize, number: i + 2 }
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 1
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useCollaboratorPayablesReportsQuery = (
  params: ICollaboratorsPayoutsSummaryReportRequest,
  config?: QueryConfig<
    ICollaboratorsPayoutsSummaryReportResponse[],
    WSServiceError
  >
) => {
  return useWSQuery<
    ICollaboratorsPayoutsSummaryReportResponse[],
    WSServiceError
  >(
    [QUERY_REPORTS_COLLABORATOR_PAYABLES_SUMMARY, params],
    async () => {
      const pageSize = 500;
      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.collaboratorPayable(params, {
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map((_, i) => () =>
        paymentsService.reports.collaboratorPayable(params, {
          page: { size: pageSize, number: i + 2 }
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 1
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const usePayablesReportsQuery = (
  payrollIds: string[],
  config?: QueryConfig<IPayrollReportResponse, WSServiceError>
) => {
  return useWSQuery<IPayrollReportResponse, WSServiceError>(
    [QUERY_REPORTS_PAYROLL, payrollIds],
    async () => {
      const pageSize = 1000;
      const filter = {
        invoiceId:
          payrollIds.length > 1
            ? { in: payrollIds }
            : {
                "=": payrollIds[0]
              }
      };

      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.payroll({
        filter,
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map((_, i) => () =>
        paymentsService.reports.payroll({
          filter,
          page: { size: pageSize, number: i + 2 }
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useOpenPayableAgingReportsQuery = (
  request: IPayableAgingReportRequest,
  config?: QueryConfig<IPayableAgingReportResponse[], WSServiceError>
) => {
  return useWSQuery<IPayableAgingReportResponse[], WSServiceError>(
    [QUERY_REPORTS_OPEN_PAYABLES_AGING, request],
    async () => {
      const pageSize = 1000;
      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.openPayableAging(request, {
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map((_, i) => () =>
        paymentsService.reports.openPayableAging(request, {
          page: { size: pageSize, number: i + 2 }
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useOpenReceivableAgingReportsQuery = (
  request: IReceivableAgingReportRequest,
  config?: QueryConfig<IReceivableAgingReportResponse[], WSServiceError>
) => {
  return useWSQuery<IReceivableAgingReportResponse[], WSServiceError>(
    [QUERY_REPORTS_OPEN_RECEIVABLE_AGING, request],
    async () => {
      const pageSize = 1000;
      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.openReceivableAging(request, {
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map((_, i) => () =>
        paymentsService.reports.openReceivableAging(request, {
          page: { size: pageSize, number: i + 2 }
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useOpenLineItemAgingReportsQuery = (
  request: ILineItemsAgingReportRequest,
  config?: QueryConfig<ILineItemsAgingReportResponse[], WSServiceError>
) => {
  return useWSQuery<ILineItemsAgingReportResponse[], WSServiceError>(
    [QUERY_REPORTS_OPEN_LINE_ITEM_AGING, request],
    async () => {
      const pageSize = 1000;
      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.openLineItemAging(request, {
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map((_, i) => () =>
        paymentsService.reports.openLineItemAging(request, {
          page: { size: pageSize, number: i + 2 }
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useQueryPayrollImmediatePayables = (
  config?: InfiniteQueryConfig<PayablesWithSummary, WSServiceError>
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();

  const size = 100;

  const query = useWSInfiniteQuery<PayablesWithSummary, WSServiceError>(
    [QUERY_PAYROLL_IMMEDIATE_PAYABLES, { size }],
    (_, __, pageNumber = 1) => {
      return paymentsService.payroll.immediate.payable.list({
        page: {
          size,
          number: pageNumber
        },
        sort: {
          updatedAt: "desc"
        }
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.data.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      enabled: hasPaymentsScope,
      ...config
    }
  );

  const data = query.data
    ? {
        data: flatten(query.data.map(page => page.data)),
        summary: query.data[0]?.summary
      }
    : undefined;

  return {
    ...query,
    data
  };
};

export const useQueryPayables = (
  pageSize: number = 20,
  params?: {
    filters?: PayablesFilter;
    userId?: string;
  },
  config?: InfiniteQueryConfig<PayablesWithSummary, WSServiceError>
) => {
  const query = useWSInfiniteQuery<PayablesWithSummary, WSServiceError>(
    [
      QUERY_PAYABLES,
      { filters: params?.filters, size: pageSize, userId: params?.userId }
    ],
    (_, __, pageNumber = 1) => {
      return paymentsService.payable.list(
        {
          filter: params?.filters,
          page: {
            size: pageSize,
            number: pageNumber
          },
          sort: {
            "events.paidAt": "desc"
          }
        },
        params?.userId
      );
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.data.length < pageSize) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  const data = query.data
    ? {
        data: flatten(query.data.map(page => page.data)),
        summary: query.data[0]?.summary
      }
    : undefined;

  return {
    ...query,
    data
  };
};

const getPartialQueryKey = (queryKey: string) => queryKey + "_PARTIAL";

export const getAllByParts = async <L extends ListRequestQuery<any, any>>(
  entity: "invoice" | "invoiceTemplate" | "memberClient",
  queryKey: string,
  query: L,
  concurrentLimit: number = 1,
  onFinished?: () => void
) => {
  const isCacheEmpty = !queryCache.getQueryData(queryKey);
  const listSize = await paymentsService[entity].listSize(query);
  const pagesSize = Math.ceil(listSize / 100);
  const pages: any[][] = [];

  const actions = times(pagesSize).map(n => async () => {
    const page = await paymentsService[entity].list({
      ...query,
      page: { size: 100, number: n + 1 }
    });

    pages.push(page);

    if (isCacheEmpty) {
      queryCache.setQueryData(getPartialQueryKey(queryKey), true);
      queryCache.setQueryData(queryKey, flatten(pages));
    }

    if (entity === "memberClient") {
      page.forEach((memberClient: any) => {
        queryCache.setQueryData(
          [QUERY_MEMBER_CLIENT, memberClient.memberClientId],
          memberClient
        );
      });
    }
  });

  await concurrentActions(actions, { concurrentLimit });

  queryCache.setQueryData(getPartialQueryKey(queryKey), false);
  const flattened = flatten(pages);

  if (onFinished) {
    onFinished();
  }

  return flattened;
};
